import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import SEO from '../components/App/SEO';
import { graphql } from 'gatsby';
import DropDownButton from '../components/App/DropDownButton'
import ReactWOW from 'react-wow'

const CrossPage = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
    <Navbar />
    <SEO 
        postTitle='Cross Platform | BlueMail App' 
        postDescription='BlueMail Plus is a mobile, web and desktop application (“Application”) made with love by Blix Inc.'
        postImage='/img/OG/og_image-devices-cross.png'
        postURL='cross-platform'
        postSEO
    />
    <div className="container-fluid cross pt-120 pb-100">
        <div className='row first-app'>
            <ReactWOW delay='.1s' animation='fadeInUp'>
                <div className='col-lg-3 col-md-3 col-xs-12 left-box'>
                    <div className='row'>
                        <div className='col pb-2'>
                            <img src='/img/cross-platform/right-top.webp' alt='BlueMail best app' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <img src='/img/cross-platform/left-bottom.webp' alt='BlueMail best app' />
                        </div>
                    </div>
                </div>
            </ReactWOW>
            <div className='col-lg-6 col-md-6 col-xs-12 middle-content'>
                <ReactWOW delay='.1s' animation='fadeInUp'>
                    <div className='row top-center'>
                        <div className='col-7 pl-2 pr-1'>
                            <img src='/img/cross-platform/center-top-left.webp' alt='BlueMail best app' />
                        </div>
                        <div className='col-5 pl-1 pr-2'>
                            <img src='/img/cross-platform/center-top-right.webp' alt='BlueMail best app' />
                        </div>
                    </div>
                </ReactWOW>
                <div className='row middle-height'>
                    <div className='col d-block m-auto'>
                        <h1 className='header-title'>A true <span>Cross Platform</span> experience</h1>
                        <h6 className='header-sub-title'>Bring BlueMail with you, wherever you go.</h6>
                        <DropDownButton />
                    </div>
                </div>
                <ReactWOW delay='.1s' animation='fadeInUp'>
                    <div className='row bottom-center'>
                        <div className='col-5 pl-2 pr-1'>
                            <img src='/img/cross-platform/center-bottom-left.webp' alt='BlueMail best app' />
                        </div>
                        <div className='col-7 pl-1 pr-2'>
                            <img src='/img/cross-platform/center-bottom-right.webp' alt='BlueMail best app' />
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <ReactWOW delay='.1s' animation='fadeInUp'>
                <div className='col-lg-3 col-md-3 col-xs-12 right-box'>
                    <div className='row'>
                        <div className='col pb-2'>
                            <img src='/img/cross-platform/left-top.webp' alt='BlueMail best app' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <img src='/img/cross-platform/right-bottom.webp' alt='BlueMail best app' />
                        </div>
                    </div>
                </div>
            </ReactWOW>
        </div>
        <div className='container pt-120 pb-4'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                    <h1>always with <span>you</span></h1>
                    <p>
                    BlueMail is a cross-platform email client meaning our email works consistently across your devices and platforms. 
                    You’ll have all of your emails in one unified inbox, seamlessly integrated in one convenient place. We can handle any number of accounts from any email provider, 
                    all while providing you with a variety of  features that optimize your overall experience with BlueMail.
                    </p>
                </div>
                <ReactWOW delay='.1s' animation='fadeInUp'>
                    <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                        <img className='m-render' src='/img/cross-platform/always-with-you.webp' alt='BlueMail always with you' />
                    </div>
                </ReactWOW>
            </div>
        </div>
    </div>
    <div className='container-fluid cross b-Bkg'>
        <div className='row'>
            <ReactWOW delay='.1s' animation='fadeInUp'>
                <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                    <img className='m-render' src='/img/cross-platform/want-to-work.webp' alt='BlueMail Want to work' />
                </div>
            </ReactWOW>
            <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                <h1>Choose <span>where</span> you want to work</h1>
                <p>With BlueMail, you have the freedom to work anywhere and when it's best for you. You can 
                    start an email from your workplace PC, review it in-transit, and finalize it when you get 
                    home. It's that simple to pickup from where you left off.
                </p>
            </div>                
        </div>
        <div className='row m-reverse'>
            <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                <h1>Your <span>everything</span> email</h1>
                <p>We strive every day to perfect your email and user experience. Our features make managing your account simple,
                     and we constantly work to improve those features. With everything you need to be at the top of your game, we are proud to be your everything email.
                </p>
            </div>
            <ReactWOW delay='.1s' animation='fadeInUp'>
                <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                    <img className='m-render' src='/img/cross-platform/when-to-work.webp' alt='BlueMail When you work' />
                </div>
            </ReactWOW>
        </div>
    </div>
    <div className='container cross pt-120 pb-100'>
        <div className='row'>
            <ReactWOW delay='.1s' animation='fadeInUp'>
                <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                    <img className='m-render' src='/img/cross-platform/spread-the-magic.webp' alt='BlueMail Magic' />
                </div>
            </ReactWOW>
            <div className='col-lg-6 col-md-6 col-xs-12 d-block m-auto'>
                <h1>And Spread the <span>Magic</span></h1>
                <p>With <b>MagicSync</b>, you can backup, restore, and securely transfer your accounts and settings 
                    between all your devices. It’s a breeze to set up BlueMail on a new device or keep several 
                    existing devices in sync.
                </p>
            </div>            
        </div>
    </div>
    <div className='container-fluid cross b-Bkg'>
        <div className='row'>
            <div className='col-lg-4 col-md-4 col-xs-12 d-block m-auto'>
                <h1><span>Any Device Anywhere</span></h1>
                <p style={{marginBottom: "44px"}}>With support for every account, device, and platform, BlueMail is always at your service.</p>
                <DropDownButton />
            </div>   
            <ReactWOW delay='.1s' animation='fadeInUp'>
                <div className='col-lg-8 col-md-8 col-xs-12 d-block m-auto'>
                    <img className='m-render' src='/img/cross-platform/all-devices-cross.webp' alt='BlueMail For all devices' />
                </div>
            </ReactWOW>
        </div>
    </div>
    <div className='pb-100'></div>
    <Footer />
    </Layout>
);

export default CrossPage;

export const query = graphql`
query CrossPagePageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`